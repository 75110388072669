<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} RESULT</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- SPENT -->
      <div>
        <vs-label text="Spent ($)" />
        <vs-input
          v-model="dataSpent"
          type="number"
          step="0.01"
          min="0"
          class="w-full"
          name="spent"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('spent')">
          {{ errors.first('spent') }}
        </span>
      </div>

      <!-- MEASURE DATE -->
      <div class="mt-4">
        <vs-label text="Measure date" />
        <datepicker
          v-model="dataMeasureDate"
          placeholder="Select Date"
          class="w-full"
        />
      </div>

      <!-- SESSIONS ON PDP SINCE START DAY -->
      <div class="mt-4">
        <vs-label text="Sessions on pdp since start day" />
        <vs-input
          v-model="dataSessionsOnPdpSinceStartDay"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="sessions_on_pdp_since_start_day"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('sessions_on_pdp_since_start_day')"
        >
          {{ errors.first('sessions_on_pdp_since_start_day') }}
        </span>
      </div>

      <!-- SOLD SINCE START DAY -->
      <div class="mt-4">
        <vs-label text="Sold since start day" />
        <vs-input
          v-model="dataSoldSinceStartDay"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="sold_since_start_day"
          v-validate="'required'"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('sold_since_start_day')"
        >
          {{ errors.first('sold_since_start_day') }}
        </span>
      </div>

      <!-- PRODUCT PRICE -->
      <div class="mt-4">
        <vs-label text="Product price" />
        <vs-input
          v-model="dataProductPrice"
          type="number"
          step="0.01"
          min="0"
          class="w-full"
          name="product_price"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('product_price')">
          {{ errors.first('product_price') }}
        </span>
      </div>

      <!-- REVIEWS -->
      <div class="mt-4">
        <vs-label text="Reviews" />
        <vs-input
          v-model="dataReviews"
          type="number"
          step="1"
          min="0"
          class="w-full"
          name="reviews"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('reviews')">
          {{ errors.first('reviews') }}
        </span>
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    reviewResult: {
      type: Array,
      default: () => [],
    },
    reviewResultId: {
      type: [Number, null],
      default: null,
    },
  },
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      dataId: null,
      dataSpent: 0,
      dataMeasureDate: new Date(),
      dataSessionsOnPdpSinceStartDay: 0,
      dataSoldSinceStartDay: 0,
      dataProductPrice: 0,
      dataReviews: 0,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          spent,
          measure_date,
          sessions_on_pdp_since_start_day,
          sold_since_start_day,
          product_price,
          reviews,
        } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataSpent = spent;
        this.dataMeasureDate = measure_date;
        this.dataSessionsOnPdpSinceStartDay = sessions_on_pdp_since_start_day;
        this.dataSoldSinceStartDay = sold_since_start_day;
        this.dataProductPrice = product_price;
        this.dataReviews = reviews;

        this.initValues();
      }
    },
  },
  computed: {
    campaignProductId() {
      return parseInt(this.$route.params.productId);
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataSpent &&
        this.dataMeasureDate &&
        this.dataSessionsOnPdpSinceStartDay &&
        this.dataSoldSinceStartDay &&
        this.dataProductPrice &&
        this.dataReviews
      );
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addReviewResultItem,
      CampaignAction.updateReviewResultItem,
    ]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataSpent = 0;
      this.dataMeasureDate = new Date();
      this.dataSessionsOnPdpSinceStartDay = 0;
      this.dataSoldSinceStartDay = 0;
      this.dataProductPrice = 0;
      this.dataReviews = 0;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        campaignProductId: this.campaignProductId,
        review_result_id: this.reviewResultId,
        spent: this.dataSpent,
        measure_date: this.dataMeasureDate,
        sessions_on_pdp_since_start_day: this.dataSessionsOnPdpSinceStartDay,
        sold_since_start_day: this.dataSoldSinceStartDay,
        product_price: this.dataProductPrice,
        reviews: this.dataReviews,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          const item = await this.updateReviewResultItem(obj);
          this.$emit('update-review-result-item', item);

          this.notifySuccess(
            'Review Result item updated',
            'The review result item has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(
            error,
            'Problem with updating a review result item.'
          );
        }
      } else {
        delete obj.id;

        try {
          const item = await this.addReviewResultItem(obj);
          this.$emit('add-review-result-item', item);

          this.notifySuccess(
            'Review Result item added',
            'The review result item has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a review result item.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
