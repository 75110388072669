<template>
  <div id="campaign-config-page">
    <h3 class="mb-4" v-show="campaignName">
      Campaign:
      <router-link :to="`/campaigns/${campaign.id}`">
        {{ campaignName }}
      </router-link>
    </h3>
    <h6 class="mb-4" v-show="product">Product: {{ product.name }}</h6>

    <status-search-sidebar
      :is-sidebar-active="statusSearchSidebarOpened"
      @close-sidebar="toggleStatusSearchSidebar"
      @add-status-search="addToStatusSearchList"
      @update-status-search="updateStatusSearchList"
      :data="statusSearchSidebarData"
      :statusSearch="statusSearch"
    />

    <display-and-paid-search-planning-sidebar
      :is-sidebar-active="displayAndPaidSearchPlanningSidebarOpened"
      @close-sidebar="toggleDisplayAndPaidSearchPlanningSidebar"
      @add-display-and-paid-search-planning="
        addToDisplayAndPaidSearchPlanningList
      "
      @update-display-and-paid-search-planning="
        updateDisplayAndPaidSearchPlanningList
      "
      :data="displayAndPaidSearchPlanningSidebarData"
    />

    <display-and-paid-search-result-sidebar
      :is-sidebar-active="displayAndPaidSearchResultSidebarOpened"
      @close-sidebar="toggleDisplayAndPaidSearchResultSidebar"
      @update-display-and-paid-search-result="
        updateDisplayAndPaidSearchResultList
      "
      :data="displayAndPaidSearchResultSidebarData"
    />

    <review-planning-sidebar
      :is-sidebar-active="reviewPlanningSidebarOpened"
      @close-sidebar="toggleReviewPlanningSidebar"
      @add-review-planning-item="addToReviewPlanningItemsList"
      @update-review-planning-item="updateReviewPlanningItemsList"
      :data="reviewPlanningSidebarData"
      :reviewPlanning="reviewPlanning.items"
      :reviewPlanningId="reviewPlanning.id"
    />

    <review-result-sidebar
      :is-sidebar-active="reviewResultSidebarOpened"
      @close-sidebar="toggleReviewResultSidebar"
      @add-review-result-item="addToReviewResultItemsList"
      @update-review-result-item="updateReviewResultItemsList"
      :data="reviewResultSidebarData"
      :reviewResult="reviewResult.items"
      :reviewResultId="reviewResult.id"
    />

    <crm-planning-sidebar
      :is-sidebar-active="crmPlanningSidebarOpened"
      @close-sidebar="toggleCrmPlanningSidebar"
      @add-crm-planning="addToCrmPlanningList"
      @update-crm-planning="updateCrmPlanningList"
      :data="crmPlanningSidebarData"
    />

    <crm-result-sidebar
      :is-sidebar-active="crmResultSidebarOpened"
      @close-sidebar="toggleCrmResultSidebar"
      @update-crm-result="updateCrmResultList"
      :data="crmResultSidebarData"
    />

    <social-media-planning-sidebar
      :is-sidebar-active="socialMediaPlanningSidebarOpened"
      @close-sidebar="toggleSocialMediaPlanningSidebar"
      @add-social-media-planning="addToSocialMediaPlanningList"
      @update-social-media-planning="updateSocialMediaPlanningList"
      :data="socialMediaPlanningSidebarData"
    />

    <social-media-result-sidebar
      :is-sidebar-active="socialMediaResultSidebarOpened"
      @close-sidebar="toggleSocialMediaResultSidebar"
      @update-social-media-result="updateSocialMediaResultList"
      :data="socialMediaResultSidebarData"
    />

    <vx-card class="mt-10">
      <vs-tabs>
        <!-- STATUS SEARCH -->
        <!-- <vs-tab label="Status Search" class="mt-5 p-0">
          <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
            <button-create
              v-if="ableToEditCampaign(campaignUserId)"
              title="Add new"
              @click="openStatusSearchSidebar"
            />
          </div>

          <status-search-list
            :data="statusSearch"
            :user-id="campaignUserId"
            :onEdit="editStatusSearch"
            :onDelete="deleteStatusSearch"
          />
        </vs-tab> -->
        <!-- / STATUS SEARCH -->

        <!-- DISPLAY AND PAID SEARCH -->
        <vs-tab label="Display and Paid Search" class="mt-5 p-0">
          <vs-tabs>
            <vs-tab label="Planning" class="p-0">
              <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                <button-create
                  v-if="ableToEditCampaign(campaignUserId)"
                  title="Add new"
                  @click="openDisplayAndPaidSearchPlanningSidebar"
                />
              </div>

              <display-and-paid-search-planning-card
                v-for="item in displayAndPaidSearchPlanning"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @delete-display-and-paid-search-planning="
                  deleteDisplayAndPaidSearchPlanning
                "
                @edit-display-and-paid-search-planning="
                  editDisplayAndPaidSearchPlanning
                "
              />
            </vs-tab>

            <vs-tab label="Result" class="mt-10 p-0">
              <display-and-paid-search-result-card
                v-for="item in displayAndPaidSearchResult"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @edit-display-and-paid-search-result="
                  editDisplayAndPaidSearchResult
                "
              />
            </vs-tab>
          </vs-tabs>
        </vs-tab>
        <!-- / DISPLAY AND PAID SEARCH PLANNING -->

        <!-- REVIEW -->
        <vs-tab label="Review" class="mt-5 p-0">
          <vs-tabs>
            <vs-tab label="Planning" class="p-0">
              <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                <button-create
                  v-if="ableToEditCampaign(campaignUserId)"
                  title="Add new"
                  @click="openReviewPlanningSidebar"
                />
              </div>

              <review-planning-tab
                :data="reviewPlanning"
                :user-id="campaignUserId"
                :onEdit="editReviewPlanning"
                :onDelete="deleteReviewPlanning"
              />
            </vs-tab>

            <vs-tab label="Result" class="p-0">
              <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                <button-create
                  v-if="ableToEditCampaign(campaignUserId)"
                  title="Add new"
                  @click="openReviewResultSidebar"
                />
              </div>

              <review-result-tab
                :data="reviewResult"
                :user-id="campaignUserId"
                :onEdit="editReviewResult"
                :onDelete="deleteReviewResult"
              />
            </vs-tab>
          </vs-tabs>
        </vs-tab>
        <!-- / REVIEW -->

        <!-- CRM -->
        <vs-tab label="CRM" class="mt-5 p-0">
          <vs-tabs>
            <vs-tab label="Planning" class="p-0">
              <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                <button-create
                  v-if="ableToEditCampaign(campaignUserId)"
                  title="Add new"
                  @click="openCrmPlanningSidebar"
                />
              </div>

              <crm-planning-card
                v-for="item in crmPlanning"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @delete-crm-planning="deleteCrmPlanning"
                @edit-crm-planning="editCrmPlanning"
              />
            </vs-tab>

            <vs-tab label="Result" class="mt-10 p-0">
              <crm-result-card
                v-for="item in crmResult"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @edit-crm-result="editCrmResult"
              />
            </vs-tab>
          </vs-tabs>
        </vs-tab>
        <!-- / CRM -->

        <!-- SOCIAL MEDIA -->
        <vs-tab label="Social Media" class="mt-5 p-0">
          <vs-tabs>
            <vs-tab label="Planning" class="p-0">
              <div class="flex flex-wrap-reverse items-center mt-10 mb-5">
                <button-create
                  v-if="ableToEditCampaign(campaignUserId)"
                  title="Add new"
                  @click="openSocialMediaPlanningSidebar"
                />
              </div>

              <social-media-planning-card
                v-for="item in socialMediaPlanning"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @delete-social-media-planning="deleteSocialMediaPlanning"
                @edit-social-media-planning="editSocialMediaPlanning"
              />
            </vs-tab>

            <vs-tab label="Result" class="mt-10 p-0">
              <social-media-result-card
                v-for="item in socialMediaResult"
                :data="item"
                :key="item.id"
                :user-id="campaignUserId"
                @edit-social-media-result="editSocialMediaResult"
              />
            </vs-tab>
          </vs-tabs>
        </vs-tab>
        <!-- / SOCIAL MEDIA -->
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import ButtonCreate from '@/views/components/buttons/ButtonCreate';
// import StatusSearchList from '@/views/ui-elements/campaign-product/StatusSearchList';
import StatusSearchSidebar from '@/views/sidebars/StatusSearchSidebar';
import DisplayAndPaidSearchPlanningSidebar from '@/views/sidebars/DisplayAndPaidSearchPlanningSidebar';
import DisplayAndPaidSearchPlanningCard from '@/views/ui-elements/campaign-product/DisplayAndPaidSearchPlanningCard';
import DisplayAndPaidSearchResultSidebar from '@/views/sidebars/DisplayAndPaidSearchResultSidebar';
import DisplayAndPaidSearchResultCard from '@/views/ui-elements/campaign-product/DisplayAndPaidSearchResultCard';
import CrmPlanningSidebar from '@/views/sidebars/CrmPlanningSidebar';
import CrmPlanningCard from '@/views/ui-elements/campaign-product/CrmPlanningCard';
import CrmResultSidebar from '@/views/sidebars/CrmResultSidebar';
import CrmResultCard from '@/views/ui-elements/campaign-product/CrmResultCard';
import SocialMediaPlanningSidebar from '@/views/sidebars/SocialMediaPlanningSidebar';
import SocialMediaPlanningCard from '@/views/ui-elements/campaign-product/SocialMediaPlanningCard';
import SocialMediaResultSidebar from '@/views/sidebars/SocialMediaResultSidebar';
import SocialMediaResultCard from '@/views/ui-elements/campaign-product/SocialMediaResultCard';
import ReviewPlanningTab from '@/views/ui-elements/campaign-product/ReviewPlanningTab';
import ReviewPlanningSidebar from '@/views/sidebars/ReviewPlanningSidebar';
import ReviewResultTab from '@/views/ui-elements/campaign-product/ReviewResultTab';
import ReviewResultSidebar from '@/views/sidebars/ReviewResultSidebar';

import { CampaignAction } from '@/store/actionTypes';
import moduleCampaign from '@/store/campaigns/moduleCampaign';

import manageCampaignMixin from '@/mixins/manageCampaignMixin';

export default {
  mixins: [manageCampaignMixin],
  data: () => ({
    campaign: {},
    product: {},
    statusSearchSidebarOpened: false,
    statusSearchSidebarData: {},
    statusSearch: [],
    displayAndPaidSearchPlanningSidebarOpened: false,
    displayAndPaidSearchPlanningSidebarData: {},
    displayAndPaidSearchPlanning: [],
    displayAndPaidSearchResultSidebarOpened: false,
    displayAndPaidSearchResultSidebarData: {},
    displayAndPaidSearchResult: [],
    reviewPlanningSidebarOpened: false,
    reviewPlanningSidebarData: {},
    reviewPlanning: {},
    reviewResultSidebarOpened: false,
    reviewResultSidebarData: {},
    reviewResult: {},
    crmPlanningSidebarOpened: false,
    crmPlanningSidebarData: {},
    crmPlanning: [],
    crmResultSidebarOpened: false,
    crmResultSidebarData: {},
    crmResult: [],
    socialMediaPlanningSidebarOpened: false,
    socialMediaPlanningSidebarData: {},
    socialMediaPlanning: [],
    socialMediaResultSidebarOpened: false,
    socialMediaResultSidebarData: {},
    socialMediaResult: [],
  }),
  components: {
    ButtonCreate,
    // StatusSearchList,
    StatusSearchSidebar,
    DisplayAndPaidSearchPlanningSidebar,
    DisplayAndPaidSearchPlanningCard,
    DisplayAndPaidSearchResultSidebar,
    DisplayAndPaidSearchResultCard,
    CrmPlanningSidebar,
    CrmPlanningCard,
    CrmResultSidebar,
    CrmResultCard,
    SocialMediaPlanningSidebar,
    SocialMediaPlanningCard,
    SocialMediaResultSidebar,
    SocialMediaResultCard,
    ReviewPlanningTab,
    ReviewPlanningSidebar,
    ReviewResultTab,
    ReviewResultSidebar,
  },
  computed: {
    campaignProductId() {
      return parseInt(this.$route.params.productId);
    },
    campaignName() {
      return this.campaign.name;
    },
    campaignUserId() {
      return this.campaign.user_id;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.fetchProductConfig,
      CampaignAction.fetchDisplayAndPaidSearchResult,
      CampaignAction.fetchCrmResult,
      CampaignAction.fetchSocialMediaResult,
      CampaignAction.updateProductConfig,
      CampaignAction.removeStatusSearch,
      CampaignAction.removeDisplayAndPaidSearchPlanning,
      CampaignAction.removeCrmPlanning,
      CampaignAction.removeSocialMediaPlanning,
      CampaignAction.removeReviewPlanningItem,
      CampaignAction.removeReviewResultItem,
    ]),

    registerStoreModules() {
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }
    },
    async save(details) {
      this.$vs.loading();

      try {
        const obj = { campaignProductId: this.campaignProductId, ...details };

        const config = await this.updateProductConfig(obj);
        this.setData(config);

        this.notifySuccess(
          'Campaign updated',
          'The campaign configuration has been successfully updated.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with saving a campaign config.');
      }

      this.$vs.loading.close();
    },
    // Open
    openStatusSearchSidebar() {
      this.statusSearchSidebarData = {};
      this.toggleStatusSearchSidebar(true);
    },
    openDisplayAndPaidSearchPlanningSidebar() {
      this.displayAndPaidSearchPlanningSidebarData = {};
      this.toggleDisplayAndPaidSearchPlanningSidebar(true);
    },
    openDisplayAndPaidSearchResultSidebar() {
      this.displayAndPaidSearchResultSidebarData = {};
      this.toggleDisplayAndPaidSearchResultSidebar(true);
    },
    openCrmPlanningSidebar() {
      this.crmPlanningSidebarData = {};
      this.toggleCrmPlanningSidebar(true);
    },
    openCrmResultSidebar() {
      this.crmResultSidebarData = {};
      this.toggleCrmResultSidebar(true);
    },
    openSocialMediaPlanningSidebar() {
      this.socialMediaPlanningSidebarData = {};
      this.toggleSocialMediaPlanningSidebar(true);
    },
    openSocialMediaResultSidebar() {
      this.socialMediaResultSidebarData = {};
      this.toggleSocialMediaResultSidebar(true);
    },
    openReviewPlanningSidebar() {
      this.reviewPlanningSidebarData = {};
      this.toggleReviewPlanningSidebar(true);
    },
    openReviewResultSidebar() {
      this.reviewResultSidebarData = {};
      this.toggleReviewResultSidebar(true);
    },
    // Edit
    editStatusSearch(item) {
      this.statusSearchSidebarData = item;
      this.toggleStatusSearchSidebar(true);
    },
    editDisplayAndPaidSearchPlanning(item) {
      this.displayAndPaidSearchPlanningSidebarData = item;
      this.toggleDisplayAndPaidSearchPlanningSidebar(true);
    },
    editDisplayAndPaidSearchResult(item) {
      this.displayAndPaidSearchResultSidebarData = item;
      this.toggleDisplayAndPaidSearchResultSidebar(true);
    },
    editCrmPlanning(item) {
      this.crmPlanningSidebarData = item;
      this.toggleCrmPlanningSidebar(true);
    },
    editCrmResult(item) {
      this.crmResultSidebarData = item;
      this.toggleCrmResultSidebar(true);
    },
    editSocialMediaPlanning(item) {
      this.socialMediaPlanningSidebarData = item;
      this.toggleSocialMediaPlanningSidebar(true);
    },
    editSocialMediaResult(item) {
      this.socialMediaResultSidebarData = item;
      this.toggleSocialMediaResultSidebar(true);
    },
    editReviewPlanning(item) {
      this.reviewPlanningSidebarData = item;
      this.toggleReviewPlanningSidebar(true);
    },
    editReviewResult(item) {
      this.reviewResultSidebarData = item;
      this.toggleReviewResultSidebar(true);
    },
    // Add
    addToStatusSearchList(item) {
      this.statusSearch.push(item);
    },
    addToDisplayAndPaidSearchPlanningList(item) {
      this.displayAndPaidSearchPlanning.push(item);
      this.refetchDisplayAndPaidSearchResults();
    },
    addToCrmPlanningList(item) {
      this.crmPlanning.push(item);
      this.refetchCrmResults();
    },
    addToSocialMediaPlanningList(item) {
      this.socialMediaPlanning.push(item);
      this.refetchSocialMediaResults();
    },
    async addToReviewPlanningItemsList(item) {
      this.reviewPlanning.items.push(item);

      this.$vs.loading();

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    async addToReviewResultItemsList(item) {
      this.reviewResult.items.push(item);

      this.$vs.loading();

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    // Update
    updateStatusSearchList(item) {
      const index = this.statusSearch.findIndex((el) => el.id === item.id);
      this.$set(this.statusSearch, index, item);
    },
    updateDisplayAndPaidSearchPlanningList(item) {
      const index = this.displayAndPaidSearchPlanning.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.displayAndPaidSearchPlanning, index, item);
      this.refetchDisplayAndPaidSearchResults();
    },
    updateDisplayAndPaidSearchResultList(item) {
      const index = this.displayAndPaidSearchResult.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.displayAndPaidSearchResult, index, item);
    },
    updateCrmPlanningList(item) {
      const index = this.crmPlanning.findIndex((el) => el.id === item.id);

      this.$set(this.crmPlanning, index, item);
      this.refetchCrmResults();
    },
    updateCrmResultList(item) {
      const index = this.crmResult.findIndex((el) => el.id === item.id);

      this.$set(this.crmResult, index, item);
    },
    updateSocialMediaPlanningList(item) {
      const index = this.socialMediaPlanning.findIndex(
        (el) => el.id === item.id
      );

      this.$set(this.socialMediaPlanning, index, item);
      this.refetchSocialMediaResults();
    },
    updateSocialMediaResultList(item) {
      const index = this.socialMediaResult.findIndex((el) => el.id === item.id);

      this.$set(this.socialMediaResult, index, item);
    },
    async updateReviewPlanningItemsList(item) {
      const index = this.reviewPlanning.items.findIndex(
        (el) => el.id === item.id
      );
      this.$set(this.reviewPlanning.items, index, item);

      this.$vs.loading();

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    async updateReviewResultItemsList(item) {
      const index = this.reviewResult.items.findIndex(
        (el) => el.id === item.id
      );
      this.$set(this.reviewResult.items, index, item);

      this.$vs.loading();

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    async deleteStatusSearch(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeStatusSearch(obj);

        const filtered = this.statusSearch.filter((item) => item.id !== id);
        this.statusSearch = filtered;

        this.notifySuccess(
          'Status Search delete',
          'The status search has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a status search.');
      }

      this.$vs.loading.close();
    },
    async deleteDisplayAndPaidSearchPlanning(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeDisplayAndPaidSearchPlanning(obj);

        const filtered = this.displayAndPaidSearchPlanning.filter(
          (item) => item.id !== id
        );
        this.displayAndPaidSearchPlanning = filtered;

        // Delete matched result
        const filteredResults = this.displayAndPaidSearchResult.filter(
          (item) => item.dps_planning_id !== id
        );
        this.displayAndPaidSearchResult = filteredResults;
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a display and paid search planning.'
        );
      }

      this.$vs.loading.close();
    },
    async deleteCrmPlanning(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeCrmPlanning(obj);

        const filtered = this.crmPlanning.filter((item) => item.id !== id);
        this.crmPlanning = filtered;

        // Delete matched result
        const filteredResults = this.crmResult.filter(
          (item) => item.crm_planning_id !== id
        );
        this.crmResult = filteredResults;
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a crm planning.');
      }

      this.$vs.loading.close();
    },
    async deleteSocialMediaPlanning(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeSocialMediaPlanning(obj);

        const filtered = this.socialMediaPlanning.filter(
          (item) => item.id !== id
        );
        this.socialMediaPlanning = filtered;

        // Delete matched result
        const filteredResults = this.socialMediaResult.filter(
          (item) => item.sm_planning_id !== id
        );
        this.socialMediaResult = filteredResults;
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a social media planning.'
        );
      }

      this.$vs.loading.close();
    },
    async deleteReviewPlanning(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeReviewPlanningItem(obj);

        const filtered = this.reviewPlanning.items.filter(
          (item) => item.id !== id
        );
        this.reviewPlanning.items = filtered;

        this.notifySuccess(
          'Review Planning item deleted',
          'The review planning item has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(
          error,
          'Problem with deleting a review planning item.'
        );
      }

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    async deleteReviewResult(id) {
      this.$vs.loading();

      try {
        const obj = {
          id,
          campaignProductId: this.campaignProductId,
        };

        await this.removeReviewResultItem(obj);

        const filtered = this.reviewResult.items.filter(
          (item) => item.id !== id
        );
        this.reviewResult.items = filtered;

        this.notifySuccess(
          'Review Result item deleted',
          'The review result item has been successfully deleted.'
        );
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a review result item.');
      }

      await this.fetchConfig();

      this.$vs.loading.close();
    },
    async refetchDisplayAndPaidSearchResults() {
      this.$vs.loading();

      try {
        const { data } = await this.fetchDisplayAndPaidSearchResult(
          this.campaignProductId
        );
        this.displayAndPaidSearchResult = data;
      } catch (error) {
        this.notifyError(
          error,
          'Problem with loading display and paid search results.'
        );
      }

      this.$vs.loading.close();
    },
    async refetchCrmResults() {
      this.$vs.loading();

      try {
        const crm = await this.fetchCrmResult(this.campaignProductId);
        this.crmResult = crm;
      } catch (error) {
        this.notifyError(error, 'Problem with loading crm results.');
      }

      this.$vs.loading.close();
    },
    async refetchSocialMediaResults() {
      this.$vs.loading();

      try {
        const socialMedia = await this.fetchSocialMediaResult(
          this.campaignProductId
        );
        this.socialMediaResult = socialMedia;
      } catch (error) {
        this.notifyError(error, 'Problem with loading social media results.');
      }

      this.$vs.loading.close();
    },
    setData(data) {
      const {
        campaign,
        product,
        statusSearch,
        displayPaidSearchPlanning,
        displayPaidSearchResult,
        reviewPlanning,
        reviewResult,
        crmPlanning,
        crmResult,
        socialMediaPlanning,
        socialMediaResult,
      } = data;

      this.campaign = campaign;
      this.product = product;
      this.statusSearch = statusSearch;
      this.displayAndPaidSearchPlanning = displayPaidSearchPlanning;
      this.displayAndPaidSearchResult = displayPaidSearchResult;
      this.reviewPlanning = reviewPlanning;
      this.reviewResult = reviewResult;
      this.crmPlanning = crmPlanning;
      this.crmResult = crmResult;
      this.socialMediaPlanning = socialMediaPlanning;
      this.socialMediaResult = socialMediaResult;
    },
    // Toggle
    toggleStatusSearchSidebar(val = false) {
      this.statusSearchSidebarOpened = val;
    },
    toggleDisplayAndPaidSearchPlanningSidebar(val = false) {
      this.displayAndPaidSearchPlanningSidebarOpened = val;
    },
    toggleDisplayAndPaidSearchResultSidebar(val = false) {
      this.displayAndPaidSearchResultSidebarOpened = val;
    },
    toggleCrmPlanningSidebar(val = false) {
      this.crmPlanningSidebarOpened = val;
    },
    toggleCrmResultSidebar(val = false) {
      this.crmResultSidebarOpened = val;
    },
    toggleSocialMediaPlanningSidebar(val = false) {
      this.socialMediaPlanningSidebarOpened = val;
    },
    toggleSocialMediaResultSidebar(val = false) {
      this.socialMediaResultSidebarOpened = val;
    },
    toggleReviewPlanningSidebar(val = false) {
      this.reviewPlanningSidebarOpened = val;
    },
    toggleReviewResultSidebar(val = false) {
      this.reviewResultSidebarOpened = val;
    },
    async fetchConfig() {
      try {
        const config = await this.fetchProductConfig(this.campaignProductId);
        this.setData(config);
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    await this.fetchConfig();

    this.$vs.loading.close();
  },
};
</script>

<style lang="scss" scoped>
.heading {
  font-size: 14px;
}

div,
p,
span {
  font-size: 12px;
}

.con-vs-tabs {
  overflow: initial;
}
</style>
