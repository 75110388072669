<template>
  <div class="flex flex-col">
    <div class="data-list-container">
      <vs-table ref="table" :data="data.items">
        <template slot="thead">
          <vs-th sort-key="type_of_review">Type of review</vs-th>
          <vs-th sort-key="activity">Activity</vs-th>
          <vs-th sort-key="budget">Budget ($)</vs-th>
          <vs-th sort-key="start_date">Start Date</vs-th>
          <vs-th sort-key="end_date">End Date</vs-th>
          <vs-th sort-key="reviews_start">Reviews Start</vs-th>
          <vs-th sort-key="reviews_target">Reviews Target</vs-th>
          <vs-th sort-key="sessions_on_pdp_last_30_days"
            >Sessions on pdp last 30 days</vs-th
          >
          <vs-th sort-key="sold_last_30_days">Sold last 30 days</vs-th>
          <vs-th sort-key="product_price">Product price ($)</vs-th>
          <vs-th sort-key="uplift_cr">Uplift CR (%)</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p>{{ tr.type_of_review }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.activity }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.budget }}$</p>
              </vs-td>

              <vs-td>
                <p>
                  {{ tr.start_date }}
                </p>
              </vs-td>

              <vs-td>
                <p>{{ tr.end_date }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.reviews_start }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.reviews_target }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.sessions_on_pdp_last_30_days }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.sold_last_30_days }}</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.product_price }}$</p>
              </vs-td>

              <vs-td>
                <p>{{ tr.uplift_cr }}%</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon
                  v-if="ableToEditCampaign(userId)"
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="onEdit(tr)"
                />
                <feather-icon
                  v-if="ableToEditCampaign(userId)"
                  icon="TrashIcon"
                  svg-classes="w-5 h-5 hover:text-danger stroke-current"
                  class="ml-2"
                  @click.stop="confirmDelete(tr.id, tr.id)"
                />
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>

      <vx-card card-background="primary" content-color="#fff" class="mt-8 pt-5">
        <div class="vx-row">
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            Cost per review
            <h3 class="text-white">{{ data.cost_per_review | money }}$</h3>
          </div>
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            Conversion rate
            <h3 class="text-white">{{ data.conversion_rate | percentage }}</h3>
          </div>
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            Revenue
            <h3 class="text-white">{{ data.revenue | money }}$</h3>
          </div>
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            Uplift
            <h3 class="text-white">{{ data.uplift | numInt }}</h3>
          </div>
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            Uplift revenue
            <h3 class="text-white">{{ data.uplift_revenue | money }}$</h3>
          </div>
          <div class="vx-col lg:w-1/6 md:w-1/3 w-full lg:mb-10 mb-6">
            ROR
            <h3 class="text-white">{{ data.ror | percentage }}</h3>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import manageCampaignMixin from '@/mixins/manageCampaignMixin';

export default {
  mixins: [manageCampaignMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    userId: {
      type: [Number, null],
      default: null,
    },
  },
  methods: {
    confirmDelete(id, val) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Are you sure you want to delete ID "${val}"?`,
        accept: () => this.onDelete(id),
        acceptText: 'Delete',
      });
    },
  },
};
</script>
