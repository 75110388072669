<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} STATUS SEARCH</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- KEYWORD -->
      <div>
        <vs-label text="Keyword" />
        <vs-input
          v-model="dataKeyword"
          class="w-full"
          name="keyword"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('keyword')">{{
          errors.first('keyword')
        }}</span>
      </div>

      <!-- PRIORITY -->
      <div class="mt-4">
        <vs-label text="Priority" />
        <v-select
          :options="priorityList"
          :clearable="false"
          v-model="dataPriority"
          class="mb-4 md:mb-0"
        />
      </div>

      <!-- IN PDP -->
      <div class="mt-4">
        <vs-label text="In pdp" />
        <vs-switch color="primary" v-model="dataInPdp" />
      </div>

      <!-- RANKING PARTNER SITE -->
      <div class="mt-4">
        <vs-label text="Ranking partner site" />
        <vs-input class="inputx w-full" v-model="dataRankingPartnerSite" />
      </div>

      <!-- RANKING DATE -->
      <div class="mt-4">
        <vs-label text="Ranking date" />
        <datepicker
          placeholder="Select Date"
          class="w-full"
          v-model="dataRankingDate"
        />
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import { Priority, priorityList, getPriorityByValue } from '@/enums/Priority';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusSearch: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    vSelect,
    Datepicker,
  },
  data() {
    return {
      dataId: null,
      dataKeyword: '',
      dataPriority: Priority.LOW,
      dataInPdp: false,
      dataRankingPartnerSite: 0,
      dataRankingDate: new Date(),
      priorityList,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          keyword,
          in_pdp,
          priority,
          ranking_partner_site,
          ranking_date,
        } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataKeyword = keyword;
        this.dataPriority = getPriorityByValue(priority);
        this.dataInPdp = in_pdp;
        this.dataRankingPartnerSite = ranking_partner_site;
        this.dataRankingDate = ranking_date;

        this.initValues();
      }
    },
  },
  computed: {
    campaignProductId() {
      return parseInt(this.$route.params.productId);
    },
    isFormValid() {
      return !this.errors.any() && this.dataKeyword && this.dataRankingDate;
    },
  },
  methods: {
    ...mapActions('campaign', [
      CampaignAction.addStatusSearch,
      CampaignAction.updateStatusSearch,
    ]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataKeyword = '';
      this.dataPriority = Priority.LOW;
      this.dataInPdp = false;
      this.dataRankingPartnerSite = 0;
      this.dataRankingDate = new Date();
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        campaignProductId: this.campaignProductId,
        keyword: this.dataKeyword,
        priority: this.dataPriority.value,
        in_pdp: this.dataInPdp,
        ranking_partner_site: this.dataRankingPartnerSite,
        ranking_date: this.dataRankingDate,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          const statusSearch = await this.updateStatusSearch(obj);
          this.$emit('update-status-search', statusSearch);

          this.notifySuccess(
            'Status Search updated',
            'The status search has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a status search.');
        }
      } else {
        delete obj.id;

        try {
          const statusSearch = await this.addStatusSearch(obj);
          this.$emit('add-status-search', statusSearch);

          this.notifySuccess(
            'Status Search added',
            'The status search has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a status search.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
