<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} RESULT</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- SPENT -->
      <div>
        <vs-label text="Spent ($)" />
        <vs-input
          v-model="dataSpent"
          class="w-full"
          name="spent"
          type="number"
          step="0.01"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('spent')">
          {{ errors.first('spent') }}
        </span>
      </div>

      <!-- IMPRESSIONS -->
      <div class="mt-4">
        <vs-label text="Impressions" />
        <vs-input
          v-model="dataImpressions"
          class="w-full"
          name="impressions"
          type="number"
          step="1"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('impressions')">
          {{ errors.first('impressions') }}
        </span>
      </div>

      <!-- CLICKS -->
      <div class="mt-4">
        <vs-label text="Clicks" />
        <vs-input
          v-model="dataClicks"
          class="w-full"
          name="clicks"
          type="number"
          step="1"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('clicks')">
          {{ errors.first('clicks') }}
        </span>
      </div>

      <!-- Units sold -->
      <div class="mt-4">
        <vs-label text="Units sold" />
        <vs-input
          v-model="dataUnitsSold"
          class="w-full"
          name="units_sold"
          type="number"
          step="0.01"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('units_sold')">
          {{ errors.first('units_sold') }}
        </span>
      </div>

      <!-- PRICE PER UNIT -->
      <div class="mt-4">
        <vs-label text="Price per unit ($)" />
        <vs-input
          v-model="dataPricePerUnit"
          class="w-full"
          name="price_per_unit"
          type="number"
          step="0.01"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('price_per_unit')">
          {{ errors.first('price_per_unit') }}
        </span>
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { mapActions } from 'vuex';

import { CampaignAction } from '@/store/actionTypes';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    statusSearch: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      dataId: null,
      dataSpent: 0,
      dataImpressions: 0,
      dataClicks: 0,
      dataUnitsSold: 0,
      dataPricePerUnit: 0,
      dataCtr: 0,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;

      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {
          id,
          spent,
          impressions,
          clicks,
          units_sold,
          price_per_unit,
          ctr,
        } = JSON.parse(JSON.stringify(this.data));

        this.dataId = id;
        this.dataSpent = spent;
        this.dataImpressions = impressions;
        this.dataClicks = clicks;
        this.dataUnitsSold = units_sold;
        this.dataPricePerUnit = price_per_unit;
        this.dataCtr = ctr * 100;

        this.initValues();
      }
    },
  },
  computed: {
    campaignProductId() {
      return parseInt(this.$route.params.productId);
    },
    isFormValid() {
      return !this.errors.any();
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.updateSocialMediaResult]),

    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataSpent = 0;
      this.dataImpressions = 0;
      this.dataClicks = 0;
      this.dataUnitsSold = 0;
      this.dataPricePerUnit = 0;
      this.dataCtr = 0;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const obj = {
        id: this.dataId,
        campaignProductId: this.campaignProductId,
        spent: this.dataSpent,
        impressions: this.dataImpressions,
        clicks: this.dataClicks,
        units_sold: this.dataUnitsSold,
        price_per_unit: this.dataPricePerUnit,
        ctr: this.dataCtr,
      };

      if (this.shouldUpdate) {
        this.$vs.loading();

        try {
          const socialMedia = await this.updateSocialMediaResult(obj);
          this.$emit('update-social-media-result', socialMedia);

          this.notifySuccess(
            'Social Media Result updated',
            'The Social Media Result has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a social media result.');
        }

        this.$vs.loading.close();
      }

      this.$emit('close-sidebar');
      this.initValues();
    },
  },
};
</script>
